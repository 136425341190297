body {
    margin: 0 auto;
    padding: 0;
    min-height: 100vh;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    padding-right: 0px !important;
    overflow: initial !important;
}

.notificationsWrapper {
    position: relative;
}

.notificationsContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 64px;
    left: 50%;
    z-index: 100000;
    transform: translateX(40%);
}

@media screen and (max-width: 400px) {
    .notificationsContainer {
        position: fixed;
        top: 10px;
        right: 25px;
    }
}